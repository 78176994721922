import { Component, EventEmitter, forwardRef, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IProduct } from '@model/interfaces/product';
import { ProductService } from '../../../products/services/product.service';
import { catchError, concat, debounceTime, distinctUntilChanged, iif, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
    selector: 'product-dropdown',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ProductDropdownComponent),
            multi: true,
        },
    ],
    template: `
        <ng-container *ngIf="formControlName; else standalone" [formGroup]="formGroup">
            <ng-select
                [autofocus]="autofocus"
                appendTo="body"
                #input
                [formControlName]="formControlName"
                [bindValue]="bindValue"
                bindLabel="Name"
                [items]="product$ | async"
                (change)="onSelected.emit($event)"
                [minTermLength]="2"
                [placeholder]="placeholder"
                typeToSearchText="Please enter 2 or more characters"
                [typeahead]="productInput$"
            ></ng-select>
        </ng-container>
        <ng-template #standalone>
            <ng-select
                [autofocus]="autofocus"
                appendTo="body"
                #input
                [(ngModel)]="selectedProductId"
                [bindValue]="bindValue"
                bindLabel="Name"
                [items]="product$ | async"
                (change)="onSelected.emit($event)"
                [minTermLength]="2"
                [placeholder]="placeholder"
                typeToSearchText="Please enter 2 or more characters"
                [typeahead]="productInput$"
            ></ng-select>
        </ng-template>
    `,
})
export class ProductDropdownComponent implements OnInit {
    readonly productService = inject(ProductService);

    @ViewChild('input') input: NgSelectComponent;

    @Input() autofocus = false;
    private _selectedProductId: number;

    @Input()
    get selectedProductId(): number {
        return this._selectedProductId;
    }

    set selectedProductId(value: number) {
        this._selectedProductId = value;
        this.loadProducts();
    }
    @Input() placeholder = 'Search for a product';
    @Input() formControlName: string;
    @Input() formGroup: FormGroup;
    @Input() bindValue: string = null; // default is full product

    @Output() onSelected = new EventEmitter<IProduct>(); // emitted value may change based on bindValue

    options: IProduct[];

    product$: Observable<IProduct[]>;
    productLoading = false;
    productInput$ = new Subject<string>();

    ngOnInit() {
        this.loadProducts();
    }

    private loadProducts() {
        this.product$ = concat(
            iif(
                () => this.selectedProductId != null,
                this.productService.getProductsForProductDropdown('', this.selectedProductId).pipe(
                    tap((resp) => {
                        if (resp.length === 1) {
                            this.onSelected.emit(resp[0]);
                        }
                    }),
                ),
                of([]),
            ), // default items
            this.productInput$.pipe(
                distinctUntilChanged(),
                tap(() => (this.productLoading = true)),
                debounceTime(300),
                switchMap((term) =>
                    this.productService.getProductsForProductDropdown(term, this.selectedProductId).pipe(
                        catchError(() => of([])), // empty list on error
                        tap(() => (this.productLoading = false)),
                    ),
                ),
            ),
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    writeValue(value: any): void {
        this.input?.writeValue(value);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnChange(fn: any): void {
        this.input?.registerOnChange(fn);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnTouched(fn: any): void {
        this.input?.registerOnTouched(fn);
    }
}
